import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';

export default function useDeleteBankDataDialog() {
  const dialogStore = useDialogStore();
  return {
    open: async () => {
      return dialogStore.openDialog(DialogIdent.DELETE_BANKDATA);
    },
    getData: () => {
      return dialogStore.getDialogData(DialogIdent.DELETE_BANKDATA);
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data);
    },
    setHeadline: (headline: string) => dialogStore.setHeadline(headline),
  };
}
